<template>
  <div class="zone">
    <functionBox  :titleData="{permission_flag:'SparePart',nowID:1}" :isAssetMgt="true" @searchBtn="searchBtn"
                  @getList="getList" @clearSearch="clearSearch" @handleDownload="handleDownload" :searhLoading="loading" >
      <template>
        <li>
          <plat-select ref="platSelect" @platSelected="handlePlatSelect" :plat_id="searchParams.platform_id"></plat-select>
        </li>
        <li>
          <el-input placeholder="请输入医院/经销商/机号" v-model="searchParams.search" clearable size="small"></el-input>
        </li>
        <li>
          <el-select
              v-model="searchParams.asset_status"
              placeholder="请选择状态"
              size="small"
              filterable
              clearable
          >
            <el-option
                v-for="item in assetStatusOptions"
                :key="item.val"
                :label="item.name"
                :value="item.val"
            ></el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="timeType" placeholder="请选择时间类型" size="small" clearable>
            <el-option
                v-for="item in timeTypeList"
                :key="item.val"
                :label="item.name"
                :value="item.val"
            ></el-option>
          </el-select>
        </li>
        <li>
          <el-date-picker
              size="small"
              v-model="dateValue"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="marginLeft:10px"
              class="Calendar"
              @change="dateChange"
              @clear="clearSearchTime"
          />
        </li>
      </template>
    </functionBox>
    <div class="sheet">
      <el-table :data="tableData" stripe min-height="400" v-loading="loading" :height="tableSize== 'big' ? '960' : tableSize== 'middle' ?'630' : '430'"  @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="45"></el-table-column>
        <el-table-column prop="instrument_id" label="仪器序列号" width="130" align="center" >
          <template v-slot="{row}">
<!--            v-if="row.is_replaced"-->
            <div style="margin-left: -20px">
            <i  class="yyj" :key="row.instrument_id" style="marginRight: 5px;font-size: 13px;" :style="row.is_replaced ? 'background: #BD3124;' : 'background: transprent;'"></i>
            <span v-if="row.instrument_id" size="small">{{row.instrument_id ? row.instrument_id : '/'}}</span>
            </div>
          </template>>
        </el-table-column>
        <el-table-column prop="platform_id" label="仪器型号"  width="180" align="center">
          <template v-slot="{row}">
            <span v-if="row.platform_unique" size="small">{{row.platform_unique}}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="asset_status" label="状态"  width="150" align="center">
          <template v-slot="{row}">
            <el-tag v-if="row.asset_status" size="small" class="status-tag" :class="handleStatusClass(row.asset_status).className">{{handleStatusClass(row.asset_status).statusName}}</el-tag>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="hospital_name" label="所在医院"  min-width="250" align="center">
          <template v-slot="{row}">
            <span v-if="row.hospital_name" size="small">{{row.hospital_name}}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="customer_name" label="经销商"  min-width="230" align="center">
          <template v-slot="{row}">
            <span v-if="row.customer_name" size="small">{{row.customer_name}}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="engineer_name" label="售后人员"  min-width="130" align="center">
          <template v-slot="{row}">
            <span v-if="row.engineer_name" size="small">{{row.engineer_name}}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="install_time" label="装机时间"  min-width="160" align="center">
          <template v-slot="{row}">
            <span v-if="row.install_time" size="small">{{row.install_time}}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" :width="tableSize== 'big' ? '330' : tableSize== 'middle' ? '300' :'250'" align="center" fixed="right">
          <template v-slot="{row}">
          <el-button size="mini" class="check" @click="goDtl(row)">查看</el-button>
          <el-button size="mini" v-if="['待出库'].includes(row.instrument_status) && assetMGtObj.outStore" class="outStore" @click="goOutStore(row.instrument_id)">出库</el-button>
          <el-button size="mini" v-if="['待发货'].includes(row.instrument_status) && assetMGtObj.delivery" class="delivery" @click="goDelivery(row.instrument_id,row.instrument_status)">发货</el-button>
          <el-button size="mini" v-if="['已发货'].includes(row.instrument_status)" class="takeDelivery" @click="changeStatus(row.instrument_id,1004, '收货')">收货</el-button>
<!--          <el-button size="mini" v-if="['待装机'].includes(row.instrument_status)" class="installed" @click="changeStatus(row.instrument_id,1005)">装机</el-button>-->
          <el-button size="mini" v-if="['使用中','待返厂'].includes(row.instrument_status)" class="relocation" @click="goRelocation(row)">移机</el-button>
          <el-button size="mini" v-if="(['使用中'].includes(row.instrument_status) || (row.application_info && row.application_info.status == 1303 && row.application_info.application_uid == user_id))" class="returnFactory" @click="goReback(row)">返厂</el-button>
          <el-button size="mini" v-if="['返厂中'].includes(row.instrument_status) && (row.application_info && row.application_info.status == 1302) && assetMGtObj.returnRecive && row.is_express" class="returnFactorySign" @click="changeStatus(row.instrument_id,1012,'返厂签收')">返厂签收</el-button>
          <el-button size="mini" v-if="['维修中'].includes(row.instrument_status) && assetMGtObj.complete" class="finishMaintance" @click="changeStatus(row.instrument_id,1012,'完成维修')">完成维修</el-button>
          <el-button size="mini" v-if="['已维修'].includes(row.instrument_status) && assetMGtObj.maintanceOut" class="maintanceOut" @click="goMaintanceDelivery(row)">维修出厂</el-button>
          <el-button size="mini" v-if="['出厂中'].includes(row.instrument_status)" class="outSign" @click="changeStatus(row.instrument_id,1005,'出厂签收')">出厂签收</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="el_pagination_style" id="pagination">
<!--        :hide-on-single-page="total <= 0"-->
        <el-pagination
            background
            layout="total,sizes,prev, pager, next,jumper"
            :total="total"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="size"
            :current-page.sync="page"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
        />
      </div>
      <el-dialog
          title="下载信息确认"
          :visible.sync="confirmDialog"
          width="30%"
          :before-close="handleClose">
        <div class="export">
          <p  style="font-size: 20px;font-weight: bold">请确认信息</p>
          <p style="color: red;">导出文件时间可能较长请耐心等待</p>
          <p>文件类型：{{fileType == 2 ? 'PDF' : fileType == 1 ?  'CSV': 'EXCEL'}}</p>
          <p v-if="searchParams.platform_id">平台：{{optionsProvince.filter(item => item.platform === searchParams.platform_id)[0].platform_unique}}</p>
          <p v-if="searchParams.instrument_id && selectedNotes.length < 1">机号：{{searchParams.instrument_id}}</p>
          <p v-if="searchParams.asset_status">状态：{{assetStatusOptions.filter(item => item.val === searchParams.asset_status)[0].name}}</p>
          <p v-if="timeType">时间类型：{{timeTypeList.filter(item => item.val === timeType)[0].name }}</p>
          <p v-if="dateValue && dateValue.length > 0">时间范围：{{dateValue[0]}}~{{dateValue[1]}}</p>
          <p v-if="selectedNotes.length > 0">机号：{{selectedNotes.toString()}}</p>
          <p v-if="message">{{message}}</p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDialog = false">取 消</el-button>
          <el-button type="primary" @click="handleConfirmExport()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import TJDetailTitle from '@/components/TJDetailTitle'
import timePicker from '@/components/timePicker'
import {changeStatus, exportAsset, getAssetList} from '@/api/assetMgt'
import FunctionBox from '../../views/Statistics/components/functionBox.vue'
import PlatSelect from "@/components/PlatSelect.vue";
import options from "./mixin/options";
import assetCommon from "@/views/AssetManagement/mixin/assetCommon";
import {addMenu, getAssetMgtBtn} from "@/router/menuMain";
export default {
  components: {PlatSelect, TJDetailTitle, timePicker, FunctionBox},
  name: 'amList',  // 资产管理列表
  mixins: [options,assetCommon],
  data() {
    return {
      userRoleId: JSON.parse(localStorage.getItem('userInfo')).org_post,
      user_id: JSON.parse(localStorage.getItem('userInfo')).user_id,
      confirmDialog:false,
      searchParams:{
        search: '', // 仪器机号
        platform_id: '', //平台ID
        asset_status: '', //资产状态
        instrument_status: '', //仪器状态
        odd_stime: '', //入库开始时间
        odd_etime: '', //入库结束时间
        out_stime: '', //出库开始时间
        out_etime: '', //出库结束时间
        install_stime: '', //装机开始时间
        install_etime: '', //装机结束时间
      },
      tableSize:'',
      pageParams: {},
      status:'',
      timeType:'',
      dateValue: [],
      selectedNotes: [],
      optionsProvince: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false, //加载动画
      fileType: '',
      message: '',
      tableData: [],
      tableHeight: 0,
      searchType: '0',
      assetMGtObj: {
        outStore: false, //出库
        delivery: false, //发货
        returnRecive: false, //返厂签收
        complete: false, //完成维修
        maintanceOut: false, //维修出厂
      }
    }
  },
  created() {
    let menuList = JSON.parse(localStorage.getItem('menuList'))
    addMenu(menuList);
    this.assetMGtObj = getAssetMgtBtn()// 缓存里取按钮权限
    if(window.innerWidth<1550){
      this.tableSize = 'small'
    }else if(window.innerWidth<2400 && window.innerWidth>1550){
      this.tableSize = 'middle'
    }else{
      this.tableSize = 'big'
    }
    this.getList()
    this.getTableHeight()
  },
  mounted() {
    this.platformFun()
  },
  methods: {
    //平台下拉框
    platformFun() {
      api.platform({}).then((res) => {
        this.optionsProvince = res.data.data
      })
    },

    dateChange(val){
      if(!val){
        this.dateValue = []
      }
    },
    getTableHeight(){
      var viewportHeight = window.innerHeight;
      var viewportWidth =  window.innerWidth
      // console.log('当前页面的可视高度为：', viewportHeight, '像素');
      var element = document.getElementById('pagination');
      if(viewportWidth > 2400){
        this.tableHeight = viewportHeight - 500
      }else if(viewportWidth < 2400 &&  viewportWidth > 1550){
        this.tableHeight = viewportHeight - 400
      }else{
        this.tableHeight = viewportHeight - 400
      }
    },
    getList(){
      this.loading = true
      let params
      if(this.searchType != '1' && !!JSON.parse(sessionStorage.getItem('searchParams'))){
        if(!!sessionStorage.getItem('pageParams')){
          this.pageParams = JSON.parse(sessionStorage.getItem('pageParams')).pageParams
           this.size = this.pageParams.size || 10
           this.page=this.pageParams.page || 1
        }
        this.searchParams = {...JSON.parse(sessionStorage.getItem('searchParams')).searchParams};
        if(this.searchParams.odd_etime){
          this.dateValue[0] = this.searchParams.odd_stime
          this.dateValue[1] = this.searchParams.odd_etime
          this.timeType = 101
        }else if(this.searchParams.out_etime){
          this.dateValue[0] = this.searchParams.out_stime
          this.dateValue[1] = this.searchParams.out_etime
          this.timeType = 102
        }else if(this.searchParams.out_etime){
          this.dateValue[0] = this.searchParams.install_stime
          this.dateValue[1] = this.searchParams.install_etime
          this.timeType = 103
        }
      }
      params = {
        ...this.searchParams,
        page: this.page,
        size: this.size,
        // instrument_status: 1002,
      }
      getAssetList(params).then(res => {
        this.tableData = res.data.data
        this.total = res.data.count
        this.loading = false
      }).catch(err=>{
        this.loading =false
      })
    },
    handlePlatSelect(val){
      this.searchParams.platform_id = val
    },
    searchBtn() {  // 更新搜索条件
      this.page = 1
      if(!this.timeType && this.dateValue && this.dateValue.length > 0){
        return this.$message.error('请先选择时间类型')
      }
      if(!!this.dateValue && this.dateValue.length > 0){
        if(this.timeType == 101){ // 入库
          this.searchParams.odd_etime = this.dateValue[1]
          this.searchParams.odd_stime = this.dateValue[0]
        }else if(this.timeType == 102){ // 出库
          this.searchParams.out_etime = this.dateValue[1]
          this.searchParams.out_stime = this.dateValue[0]
        }else if(this.timeType == 103){ // 装机
          this.searchParams.install_etime = this.dateValue[1]
          this.searchParams.install_stime = this.dateValue[0]
        }
      }else{
        this.searchParams.install_etime = ''
        this.searchParams.install_stime = ''
        this.searchParams.odd_etime = ''
        this.searchParams.odd_stime = ''
        this.searchParams.out_etime = ''
        this.searchParams.out_stime =''
      }
      this.searchType = '1'
      this.getList()
      sessionStorage.setItem('searchParams', JSON.stringify({ searchParams:this.searchParams }));
      sessionStorage.setItem('pageParams', JSON.stringify({ pageParams:{page: 1,size:10} }));
    },
    clearSearchTime(){
      this.searchParams.start_time = ''
      this.searchParams.end_time = ''
      this.check_time = ''
      this.getList();
    },
    clearSearch(){
      this.searchParams.install_etime = ''
      this.searchParams.install_stime = ''
      this.searchParams.odd_etime = ''
      this.searchParams.odd_stime = ''
      this.searchParams.out_etime = ''
      this.searchParams.out_stime =''
      this.searchParams.search = '',
      this.searchParams.platform_id = '',
      this.searchParams.asset_status = '',
      this.timeType = '',
      this.dateValue = [],
      this.$refs.platSelect.platform_id = ''
      sessionStorage.setItem('searchParams', JSON.stringify({ searchParams:{}}));
      this.getList()
    },
    handleCurrentChange(val) { // 翻页
      this.page = val
      this.pageParams.size = this.size
      this.pageParams.page = this.page
      sessionStorage.setItem('pageParams', JSON.stringify({ pageParams:this.pageParams }));
      this.getList('1');
    },
    handleSizeChange(val){
      this.size = val
      this.pageParams.size = this.size
      this.pageParams.page = this.page
      sessionStorage.setItem('pageParams', JSON.stringify({ pageParams:this.pageParams }));
      this.getList('1');
    },
    handleDownload(fileType){
      this.confirmDialog = true
      this.fileType = fileType
      if(this.total > 0){
        if(this.selectedNotes.length < 1){
          this.message= `导出当前列表中所有的数据，当前仪器数据总共数据${this.total}条`
        }else{
          if(this.total > this.selectedNotes.length){
            this.message= `导出当前列表中所有的数据，当前仪器数据总共数据${this.selectedNotes.length}条`
          }else{
            this.message= `导出当前列表中所有的数据，当前仪器数据总共数据${this.total}条`
          }
        }
      }else{
        this.message= '导出数据条数应大于0！'
      }
    },
    handleClose(){
      this.confirmDialog = false
    },
    handleConfirmExport(){
      this.confirmDialog = false
      if(this.total > 0){
        this.download()
      } else{
        this.$message.error('暂无数据，无法导出！')
      }
    },
    download(){ //导出
      this.loading = true
      let params
      if(this.searchType != '1' && !!JSON.parse(sessionStorage.getItem('searchParams'))){
        if(!!sessionStorage.getItem('pageParams')){
          this.pageParams = JSON.parse(sessionStorage.getItem('pageParams')).pageParams
          this.size = this.pageParams.size || 10
          this.page=this.pageParams.page || 1
        }
        this.searchParams = {...JSON.parse(sessionStorage.getItem('searchParams')).searchParams};
        if(this.searchParams.odd_etime){
          this.dateValue[0] = this.searchParams.odd_stime
          this.dateValue[1] = this.searchParams.odd_etime
          this.timeType = 101
        }else if(this.searchParams.out_etime){
          this.dateValue[0] = this.searchParams.out_stime
          this.dateValue[1] = this.searchParams.out_etime
          this.timeType = 102
        }else if(this.searchParams.out_etime){
          this.dateValue[0] = this.searchParams.install_stime
          this.dateValue[1] = this.searchParams.install_etime
          this.timeType = 103
        }
      }
      params = {
        ...this.searchParams,
        export_type: this.fileType,
        instrument_ids: this.selectedNotes ? this.selectedNotes.toString() : this.searchParams.instrument_ids
      }
      exportAsset(params).then(res=>{
        this.loading = false
        if(this.fileType === 2){
          if(res.data){
            this.downloadPdf(res.data) // 特殊处理PDF，防止浏览器直接打开PDF进行预览

          }
        }else{
          this.downloadFile(res.data.file_url)
        }
      })
    },
    handleSelectionChange(val){
      this.selectedNotes = val.map(item => item.instrument_id)
    },
    changeStatus(instrument_id,new_status,text){ // 修改资产状态
      let params = {new_status, instrument_id}
        const h = this.$createElement
        this.$confirm('提示', {
          title: '提示',
          message: h('div', [h('p', `确认${text}？`),]),
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          changeStatus(params).then(res=>{
            if(res.code == 200){
              this.$message.success(text+ '成功！')
              this.getList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消确认',
          })
        })
    },
    goDtl(row) { //查看
      if(['返厂中','待返厂','出厂中','维修中','已维修'].includes(row.instrument_status)){
        let page_type,self_type
        if(row.instrument_status =='待返厂'){
          page_type = 'approve'
        }else if(['返厂中'].includes(row.instrument_status) && row.application_info.status == 1302){
          page_type = 'wl'
          self_type = 'zisong'
        }else{
          page_type = 'wl-check'
          if(['已维修'].includes(row.instrument_status)){
            self_type = 'ziti'
          }
        }
        this.$router.push({ path: '/assetMgt/returnFactyDtl',query:{instrument_id:row.instrument_id,page_type,instrument_status: row.instrument_status,self_type} })
      }else if(['已发货','待装机','待发货'].includes(row.instrument_status)){
        this.$router.push({ path: '/assetMgt/outStoreDtl',query:{instrument_id:row.instrument_id,page_type: row.instrument_status == '待发货' ? 'check' : 'wl-check'} })
      }else{
        this.$router.push({ path: '/assetMgt/detail',query:{instrument_id:row.instrument_id,page_type: row.instrument_status =='待返厂' ?'approve' : 'check'} })
      }
      sessionStorage.setItem('searchQuery', JSON.stringify({ searchParams: this.searchParams }));
      this.searchType = '0'
    },
    goOutStore(id){  //出库
      this.$router.push({ path: '/assetMgt/outStoreEdit',query:{instrument_id:id} })
    },
    goDelivery(id,instrument_status){  //发货
      this.$router.push({ path: '/assetMgt/outStoreDtl',query:{instrument_id:id,instrument_status:instrument_status,page_type: 'wl',self_type:'ziti'} })
    },
    goReback(row){  // 返厂申请
      this.$router.push({ path: '/assetMgt/applyReturnFacty',query:{instrument_id:row.instrument_id,application_status:row.application_info ? row.application_info.status : '',} })
    },
    goRelocation(row){  // 移机
      this.$router.push({ path: '/assetMgt/relocationRecord',query:{instrument_id:row.instrument_id,instrument_status:row.instrument_status} })
    },
    goMaintanceDelivery(row){ //维修出厂
      this.$router.push({ path: '/assetMgt/returnFactyDtl',query:{instrument_id:row.instrument_id,page_type: 'wl',instrument_status:row.instrument_status,self_type:'ziti'} })
    }
  },
}
</script>

<style scoped  lang="scss">
@import '@/assets/css/asset';
.export{
   p{
     font-size: 16px;
     line-height: 24px;
     margin-bottom: 8px;
   }
}
::v-deep .el-table th:first-child.el-table__cell>.cell{
  padding-left:14px!important;
}
::v-deep .oprateBlock .el-input--small .el-input__inner{
  background: #81B337!important;
  width: 80px;
  color: #fff;
}
::v-deep .oprateBlock .el-select{
  width: 80px;
  color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  ::v-deep .el-table th.el-table__cell > .cell{
    font-size: 15px
  }
  ::v-deep .el-table td.el-table__cell div,.status-tag{
    font-size: 13px;
  }
  .yyj{
    top: 10px;
    left: 11px;
  }
}
@media screen and (min-width: 1551px) , print {
  ::v-deep .el-table th.el-table__cell > .cell {
    font-size: 16px
  }
  ::v-deep .el-table td.el-table__cell div ,.status-tag{
    font-size: 14px;
  }
  .yyj{
    top: 34px;
    left: 11px;
  }
  ::v-deep .el-pager li{
    padding: 0 20px
  }
}
.below .left > li{
  margin-top: 0;
}
::v-deep .el-range-editor--small .el-range-input{
  font-size: 0.75rem!important;
}
::v-deep .el-table .el-table__cell{
  padding: 0.4rem 0rem;
}

::v-deep .el-pagination__sizes{
  width: 80px;
  vertical-align: top;
}
::v-deep .el-pagination__sizes .el-select{
  vertical-align: top;
}
::v-deep .el-pagination__sizes input{
  width: 80px;
  height: 1.8rem;
  border: 1px solid #999999;
}
::v-deep .el-pagination .el-input__suffix{
  right: 22px;
}
.yyj{

  margin-right: 5px;
  font-size: 13px;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 6px;
}
</style>
